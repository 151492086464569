<template>
  <div></div>
</template>
<script>
import authHelper from "@/auth";
import { mapGetters } from "vuex";

export default {
  components: {},
  created() {
    window.onpopstate = (event) => {
      if (this.$route.name == "redirect") {
        event.stopPropagation();
        this.pushRoute("redirect");

        return;
      }
    };
    if (
      authHelper.getRole() == "SuperAdmin" &&
      this.activeAccount.type == "Supplier"
    ) {
      this.pushRoute("supplier-home");
    } else if (this.activeAccount.type == "Directory") {
      this.pushRoute("directory.accounts.index");
    } else {
      this.pushRoute("home");
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ activeAccount: "appUser/getCurrentAccountScope" }),
  },
  methods: {
    pushRoute(name) {
      this.$router.push({ name: name }).catch(() => {});
    },
  },
};
</script>
